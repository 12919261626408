import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import { Box, Button, Heading } from 'grommet'

import AppLayout from '../components/app-layout'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

const resetComplete = () => (
  <AppLayout page="reset">
    <Box
      style={{ maxWidth: '48rem', marginLeft: 'auto', marginRight: 'auto' }}
      fill={true}
      pad='xlarge'
    >
      <Heading color='accent-2'>
        A password reset email has been sent
      </Heading>
      <Box
        direction='row-responsive'
        align='center'
        pad={{ top: 'xlarge' }}
        justify='center'
      >
        <Link to='/signin'>
            <Button
                style={{margin: '15px'}}
                onClick={()=> null}
                label={'back to sign in'}
                color='accent-1'
                primary
            />
        </Link>
      </Box>
    </Box>
  </AppLayout>
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(resetComplete)
